<template>
    <section class="login">
        <div class="container">
            <div class="logo">
                <img src="../assets/images/logo.svg" alt="logo realitka" data-uk-svg>
            </div>
            <div class="login-button">
                <a class="button white" v-on:click="openLogin">Přihlásit se</a>
                <a v-on:click="openRegister">Registrovat se</a>
            </div>
    <Login v-if='showLogin' @closeLogin='closeLogin' @openRecovery='openRecovery'/>
    <Register v-if='showRegister' @closeRegister='closeRegister'/>
    <Recovery v-if='showRecovery' @closeRecovery="closeRecovery"/>
        </div>
    </section>
    
</template>

<script>
import {mapActions} from "vuex"
import Register from '../components/login/Register.vue'
import Recovery from '../components/login/Recovery.vue'
import Login from '../components/login/Login.vue'

export default {
    name: "LoginView",
    components:{
        Register,
        Recovery,
        Login
    },
    data() {
        return {
            showLogin:false,
            showRegister:false,
            showRecovery:false,
        };
    },
    methods: {
        openLogin(){           
                this.showLogin=true;
        },
        closeLogin(){
                this.showLogin = false;
        },
        openRegister(event){
                this.showRegister=true;
        },
        closeRegister(){
            this.showRegister = false;
        },
        openRecovery(){
                this.showRecovery=true;
        },
        closeRecovery(){
            this.showRecovery = false;
        },
    },
}
</script>

<style lang="less" >
section.login{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    top: 0;
    left: 0;
    background: rgba(143,187,105,1);
    background: -moz-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143,187,105,1)), color-stop(100%, rgba(47,172,102,1)));
    background: -webkit-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
    background: -o-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
    background: -ms-linear-gradient(top, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
    background: linear-gradient(to bottom, rgba(143,187,105,1) 0%, rgba(47,172,102,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8fbb69', endColorstr='#2fac66', GradientType=0 );
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    .container{
        .logo{
            position: relative;
            margin-top: 8vh;
            margin-bottom: 20vh;
            img{
                position: relative;
                display: block;
                margin: auto;
                z-index: 1;
            }
            svg{
                max-height: 7.7rem;
                display: block;
                margin: auto;
                path{
                    fill: #fff;
                }
            }
            &:before{
                content:"";
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-19%,-18%);
                -ms-transform: translate(-19%,-18%);
                transform: translate(-19%,-18%);
                width: 57.8rem;
                height: 46.1rem;
                background: url('../assets/images/homea.png');
                background-size: contain;
                background-repeat: no-repeat;
                opacity: 0.8;
                z-index: 0;
            }
        }
        .login-button{
            position: absolute;
            top: 60%;
            left: 0;
            right: 0;
            margin: auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 4rem;
            z-index: 2;
            .button{
                margin-bottom: 3.7rem;
            }
            a{
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 2rem;
                line-height: 3rem;
                color: #fff;
                &:hover{
                text-decoration: underline;
                }
            }
        }
        // .login-content{
        //     position: relative;
        //     max-width: 42.8rem;
        //     width: 100%;
        //     background: #fff;
        //     height: 62vh;
        //     -webkit-box-sizing: border-box;
        //     box-sizing: border-box;
        //     padding: 4rem 7.5rem 9rem;
        //     margin: auto;
        //     bottom:-100vh;
        //     -webkit-transition-duration: 0.2s;
        //     -o-transition-duration: 0.2s;
        //     transition-duration: 0.2s;
        //     z-index: 2;
        //     &.open{
        //         bottom: 0;
        //     }
        //     .login-close{
        //         position: absolute;
        //         top: 1rem;
        //         right: 1rem;
        //         color: #2FAC66;
        //         svg{
        //             width: 2rem;
        //             line{
        //             stroke-width: 1.2;
        //             }
        //         }
        //     }
        //     h2{
        //         font-family: 'Poppins';
        //         font-weight: 700;
        //         font-size: 3rem;
        //         line-height: 4rem;
        //         color: #2FAC66;
        //         text-align: center;
        //         text-transform: uppercase;
        //         margin-top: 0;
        //         margin-bottom: 3rem;
        //     }
        //     form{
        //         .row{
        //             margin-bottom: 2rem;
        //             fieldset{
        //                 position: relative;
        //                 input{
        //                     position: relative;
        //                     width: 100%;
        //                     max-width: 27.2rem;
        //                     height: 5rem;
        //                     border: 1px solid #7C7C7B;
        //                     border-radius: 5rem;
        //                     box-sizing: border-box;
        //                     padding: 0 4rem 0 2rem;
        //                     font-family: 'Poppins';
        //                     font-weight: 400;
        //                     font-size: 1.4rem;
        //                     line-height: 3rem;
        //                     color: #7C7C7B;
        //                     outline: 0;
        //                     &:focus + label{
        //                         -webkit-transform: translate3d(0, -3rem, 0);
        //                             transform: translate3d(0, -3rem, 0);
        //                     }
        //                 }
        //                 label{
        //                     top:1.1rem;
        //                     font-size: 15px;
        //                     -webkit-transform: translate3d(0, -0rem, 0);
        //                             transform: translate3d(0, -0rem, 0);
        //                     &.yes{
        //                     font-size: 12px;
        //                     -webkit-transform: translate3d(0, -3rem, 0);
        //                             transform: translate3d(0, -3rem, 0);
        //                     border: 1px solid #b8b8b8;
        //                     padding: 3px;
        //                     background:#fff;
        //                     border-radius: 3px;
        //                     }
        //                 }
        //                 img{
        //                     position: absolute;
        //                     top: 0;
        //                     right: 1.5rem;
        //                     bottom: 0;
        //                     margin: auto;
        //                 }
        //                 &.error{
        //                     input{
        //                     border-color: #AC2F2F;
        //                     }
        //                     label{
        //                     border-color: #AC2F2F;
        //                     color: #AC2F2F;
        //                     }
        //                 }
        //                 &.succes{
        //                     input{
        //                         border-color: #04ac66;
        //                     }
        //                     label{
        //                         border-color: #04ac66;
        //                         color: #04ac66;
        //                     }
        //                 }
                        
        //             }
        //             &.end{
        //                 margin-top: 4.3rem;
        //                 a{
        //                     display: block;
        //                     font-family: 'Poppins';
        //                     font-weight: 400;
        //                     font-size: 2rem;
        //                     line-height: 3rem;
        //                     color: #7C7C7B;
        //                     text-align: center;
        //                     margin-top: 2.7rem;
        //                     margin-bottom: 2rem;
        //                 }
        //             }
        //         }
        //     }
        //     &:before{
        //         content:"";
        //         position: absolute;
        //         top: -3.6rem;
        //         left: 0;
        //         right: 0;
        //         margin: auto;
        //         width: 10.3rem;
        //         height: 6.8rem;
        //         background: url('../assets/images/keya.png');
        //         background-size: contain;
        //         background-repeat: no-repeat;
        //         z-index: 3;
        //     }
        // }
    }
}

</style>