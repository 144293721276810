<template>
            <div class="login-content" id="mylogin">
                <button class="login-close" type="button" data-uk-close v-on:click="closeLogin"></button>
                <h2>Přihlášení</h2>
                <form class="form-label form-js-label" @submit.prevent="submit">
                    <div class="row">
                        <fieldset :class="{error: showError, succes: showSucces}">
                            <input id="username" type="text" name="username" v-model="username" required>
                            <label for="username" :class="username.length > 0 ? 'yes' : 'no'">E-mail</label>
                            <img src="../../assets/images/ico-mail.png" alt="mail">
                        </fieldset>
                    </div>
                    <div class="row">
                        <fieldset :class="{error: showError, succes: showSucces}">
                            <input id="password" type="password" name="password" v-model="password" required>
                            <label for="password" :class="password > 0 ? 'yes' : 'no'">Heslo:</label>
                            <img src="../../assets/images/ico-key.png" alt="password">
                        </fieldset>
                    </div>
                    <div class="row end">
                        <button class="button" v-on:click="login = !login">Přihlásit se</button>
                        <a v-on:click="openRecovery">Zapomněli jste heslo? </a>
                    </div>
                </form>
            </div>
    <p v-if="showError" id="error" :class="{notification: showError, notificationSucces: showSucces}">{{msg}}</p>
</template>

<script>
import {mapActions, mapGetters} from "vuex"

export default {
    name: "Login",
    emits:['closeLogin','openRecovery'],
    data() {
        return {
            username: "",
            password: "",
            showError: false,
            showSucces: false,
            login: true,
            msg: 'Přihlašte se, prosím.',
        };
    },
    computed:{
        ...mapGetters(['isAuthenticated']),
    },
    mounted(){
        setTimeout(()=>{
            document.getElementById("mylogin").classList.add("open");
            },125)
    },

    methods: {
        ...mapActions(['LogIn']),
        async submit() {
            const User = {
              'username': this.username,
              'password': this.password
            };

            try {
                this.LogIn(User).then( response =>{
                  if( response.data.status === 'OK' ) {
            this.msg = response.data.info.name;
            this.showError = false;
            this.showSucces= true;
            if(this.isAuthenticated){
            this.$router.push('/')
            }
            
          }
          if( response.data.status !== 'OK' ){
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = false;
          }
          setTimeout(()=>{
            this.showError = false;
            this.showSucces = false;
          },7000)

        });
            } catch (error) {
                this.showError = true;
                this.showSucces = false;
                setTimeout(()=>{
            this.showError = false;
            this.showSucces = false;
          },7000)
            }
        },
        closeLogin: function(event){
            if (event) {
                document.getElementById("mylogin").classList.remove("open");
                setTimeout(()=>{
                this.$emit('closeLogin')
                },125)
            }
        },
        openRecovery(event){
            if (event) {
                this.$emit('openRecovery')
            }
        },
    },
}
</script>

<style lang="less" scoped>
        .login-content{
            position: relative;
            max-width: 42.8rem;
            width: 100%;
            background: #fff;
            height: 62vh;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 4rem 7.5rem 9rem;
            margin: auto;
            bottom:-100vh;
            -webkit-transition-duration: 0.2s;
            -o-transition-duration: 0.2s;
            transition-duration: 0.2s;
            z-index: 2;
            &.open{
                bottom: 0;
            }
            .login-close{
                position: absolute;
                top: 1rem;
                right: 1rem;
                color: #2FAC66;
                svg{
                    width: 2rem;
                    line{
                    stroke-width: 1.2;
                    }
                }
            }
            h2{
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 3rem;
                line-height: 4rem;
                color: #2FAC66;
                text-align: center;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 3rem;
            }
            form{
                .row{
                    margin-bottom: 2rem;
                    fieldset{
                        position: relative;
                        input{
                            position: relative;
                            width: 100%;
                            max-width: 27.2rem;
                            height: 5rem;
                            border: 1px solid #7C7C7B;
                            border-radius: 5rem;
                            box-sizing: border-box;
                            padding: 0 4rem 0 2rem;
                            font-family: 'Poppins';
                            font-weight: 400;
                            font-size: 1.4rem;
                            line-height: 3rem;
                            color: #7C7C7B;
                            outline: 0;
                            &:focus + label{
                                -webkit-transform: translate3d(0, -3rem, 0);
                                    transform: translate3d(0, -3rem, 0);
                            }
                        }
                        label{
                            top:1.1rem;
                            font-size: 15px;
                            -webkit-transform: translate3d(0, -0rem, 0);
                                    transform: translate3d(0, -0rem, 0);
                            &.yes{
                            font-size: 12px;
                            -webkit-transform: translate3d(0, -3rem, 0);
                                    transform: translate3d(0, -3rem, 0);
                            border: 1px solid #b8b8b8;
                            padding: 3px;
                            background:#fff;
                            border-radius: 3px;
                            }
                        }
                        img{
                            position: absolute;
                            top: 0;
                            right: 1.5rem;
                            bottom: 0;
                            margin: auto;
                        }
                        &.error{
                            input{
                            border-color: #AC2F2F;
                            }
                            label{
                            border-color: #AC2F2F;
                            color: #AC2F2F;
                            }
                        }
                        &.succes{
                            input{
                                border-color: #04ac66;
                            }
                            label{
                                border-color: #04ac66;
                                color: #04ac66;
                            }
                        }
                        
                    }
                    &.end{
                        margin-top: 4.3rem;
                        a{
                            display: block;
                            font-family: 'Poppins';
                            font-weight: 400;
                            font-size: 2rem;
                            line-height: 3rem;
                            color: #7C7C7B;
                            text-align: center;
                            margin-top: 2.7rem;
                            margin-bottom: 2rem;
                        }
                    }
                }
            }
            &:before{
                content:"";
                position: absolute;
                top: -3.6rem;
                left: 0;
                right: 0;
                margin: auto;
                width: 10.3rem;
                height: 6.8rem;
                background: url('../../assets/images/keya.png');
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 3;
            }
        }

</style>