<template>
  <section class="recovery" id="myRecovery">
    <button class="recovery-close" type="button" data-uk-close v-on:click="closeRecovery"></button>
    <h2>Obnova hesla</h2>
    <form class="form-label form-js-label" @submit.prevent="submit">
      <div class="row">
          <fieldset :class="{error: showError, succes: showSucces}">
              <input type="text" name="username" id="username" v-model="username" required>
              <label for="username" :class="username.length > 0 ? 'yes' : 'no'">E-mail</label>
              <img src="../../assets/images/ico-mail.png" alt="mail">
          </fieldset>
      </div>
      <div class="row end">
          <button class="button" v-on:click="recovery">Potvrdit</button>
      </div>
    </form>  
  </section>
  <p v-if="showError" id="error" :class="{notification: showError, notificationSucces: showSucces}">{{msg}}</p>
  <p v-if="showSucces" id="succes" :class="{notificationSucces: showSucces}">{{msg}}</p>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Recovery",
  emits:['closeRecovery'],
  data() {
    return {
      username: "",
      show: true,
      msg: "",
      showError: false,
      showSucces: false,
    };
  },
  mounted(){
    setTimeout(()=>{
      document.getElementById("myRecovery").classList.add("open");
      },125)
  },
  methods: {
    ...mapActions(['Recovery']),
    async submit() {
        let data =  {
          "user":
            {
              "email": this.username,
            }
        };

        await this.Recovery( data ).then( response => {
          if( response.data.status === 'OK' ) {
            this.msg = response.data.info.name;
            this.showError = false;
            this.showSucces= true;
          }
          if( response.data.status !== 'OK' ){
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = false; 
            this.closeRecovery( true )
          }
          setTimeout(()=>{
            this.showError = false;
            this.showSucces = false;
          },7000)
        });
    },
    closeRecovery: function(event){
        if (event) {
          setTimeout(()=>{
          document.getElementById('myRecovery').classList.remove('open')
          }, 1000);
          setTimeout(()=>{
            this.$emit('closeRecovery')
          }, 2000);
        }
    },
  }
}
</script>


<style lang="less" scoped>
  section.recovery{
    position: fixed;
    left: 0;
    right: 0;
    max-width: 42.8rem;
    width: 100%;
    background: #fff;
    height: 62vh;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 4rem 6.5rem 9rem;
    margin: auto;
    bottom:-100%;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    z-index: 1001;
    .recovery-close{
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: #2FAC66;
      svg{
        width: 2rem;
        line{
          stroke-width: 1.2;
        }
      }
    }
    h2{
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 3rem;
      line-height: 4rem;
      color: #2FAC66;
      text-align: center;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 3rem;
    }
    form{
      .row{
        margin-bottom: 1.7rem;
        fieldset{
            position: relative;
            input{
                position: relative;
                width: 100%;
                max-width: 30.2rem;
                height: 5rem;
                border: 1px solid #7C7C7B;
                border-radius: 5rem;
                box-sizing: border-box;
                padding: 0 4rem 0 2rem;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 3rem;
                color: #7C7C7B;
                outline: 0;
                background: #fff;
                &:focus + label{
                    -webkit-transform: translate3d(0, -3rem, 0);
                        transform: translate3d(0, -3rem, 0);
                }
            }
             label{
               top:1.1rem;
                font-size: 15px;
                -webkit-transform: translate3d(0, -0rem, 0);
                        transform: translate3d(0, -0rem, 0);
                &.yes{
                font-size: 12px;
                -webkit-transform: translate3d(0, -3rem, 0);
                        transform: translate3d(0, -3rem, 0);
                border: 1px solid #b8b8b8;
                padding: 3px;
                background:#fff;
                border-radius: 3px;
                }
            }
            img{
                position: absolute;
                top: 0;
                right: 1.5rem;
                bottom: 0;
                margin: auto;
            }
          &.error{
            input{
              border-color: #AC2F2F;
            }
            label{
              border-color: #AC2F2F;
              color: #AC2F2F;
            }
          }
          &.succes{
            input{
                border-color: #04ac66;
            }
            label{
                border-color: #04ac66;
                color: #04ac66;
            }
          }
        }
        &.end{
            margin-top: 6.3rem;
        }
      }
    }
    &.open{
      bottom: 0;
    }
    &:before{
      content:"";
      position: absolute;
      top: -3.6rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 10.3rem;
      height: 6.8rem;
      background: url('../../assets/images/keya.png');
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 3;
    }
  }
</style>